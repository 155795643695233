/* global window */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from 'HOCs/withRouter';
import { compose } from 'redux';
import classNames from 'classnames';
import { FormattedMessage as Translation } from 'react-intl';
import Checkbox from 'Components/ThematicCheckbox/ThematicCheckbox';
import { LayoutHelper } from 'olx-autos-landing-page';
import loadable from '@loadable/component';
import Search from './components/Search';
import css from './HeroSearch.scss';
import withTrack from 'HOCs/withTrack/withTrack';
import withConfig from 'HOCs/withConfig/withConfig';
import { locationToString, buildObjectFromURL, buildURL } from 'Helpers/url';
import { setItem as setSessionStorageItem } from 'Helpers/sessionStorage';
import { updateLocation, resetLocation } from 'Actions/selectLocation';
import { selectedLocationSelector, isCurrentGPSLocationSelector } from 'Selectors/location';
import { setGPS, setLocationGps } from 'Actions/locations';
import { categorySelector, categoryIdSelector } from 'Selectors/categories';
import { LOCATION_COMPLETE, OMNIBOX, SEARCH_COMPLETE, SEARCH_TYPE } from 'Constants/tracking';
import OutsideClickListener from 'Components/OutsideClickListener/OutsideClickListener';
import { searchSanitization } from 'Helpers/strings';
import { isEmpty } from 'Helpers/objects';
import { add as setManualLocation } from 'Helpers/manualLocation';
import Icon from 'Components/ThematicIcon/ThematicIcon';
import { THEMES } from 'Constants/bundles';
import { locationByHomeAndLatLong } from 'Helpers/locations';

const LocationAutocompleteMX = loadable(() => import(/* webpackChunkName: "locationMX" */'Components/SelectLocationMX/LocationAutocomplete/LocationAutocomplete'));
const LocationAutocompleteOld = loadable(() => import(/* webpackChunkName: "locationOld" */'Components/SelectLocation/LocationAutocomplete/LocationAutocomplete'));

export class HeroSearch extends React.PureComponent {
    static propTypes = {
        isBrowsingInCategory: PropTypes.bool,
        isBrowsingInGeoLocation: PropTypes.bool,
        isMeetingPage: PropTypes.bool,
        searchTerm: PropTypes.string,
        params: PropTypes.object,
        trackOrigin: PropTypes.string,
        router: PropTypes.shape({
            push: PropTypes.func,
            location: PropTypes.object
        }),
        location: PropTypes.shape({
            query: PropTypes.object,
            id: PropTypes.string,
            gps: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object
            ]),
            type: PropTypes.string
        }).isRequired,
        selectedLocation: PropTypes.object,
        _setGPS: PropTypes.func.isRequired,
        selectedCategory: PropTypes.object,
        track: PropTypes.func.isRequired,
        _setLocationGps: PropTypes.func.isRequired,
        config: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired
    };

    static defaultProps = {
        isBrowsingInCategory: false,
        isBrowsingInGeoLocation: false,
        searchTerm: '',
        params: {},
        trackOrigin: ''
    };

    constructor(props) {
        super(props);
        this.isMXCL = props.config.get('olxAutos', 'isMXCL');
        this.searchTrackInfo = {
            autocomplete_version: props.config.get('autocompleteVersion'),
            resultset_type: props.trackOrigin
        };
        this.urlKeywords = props.config.get('olxAutos', 'urlKeywords');
        this.theme = props.config.get('theme', 'id');
    }

    componentDidUpdate(prevProps) {
        if (prevProps.searchTerm !== this.props.searchTerm) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ string: this.props.searchTerm });
        }

        const isCategoryIdDifferent = prevProps.params.categoryID !== this.props.params.categoryID;
        const isSelectedCatDifferent = prevProps.selectedCategory !== this.props.selectedCategory;
        const isSelectedCatIdDifferent = (prevProps.selectedCategory && this.props.selectedCategory
            && prevProps.selectedCategory.id !== this.props.selectedCategory.id);

        if (isCategoryIdDifferent || isSelectedCatDifferent || isSelectedCatIdDifferent) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ onlyInCategory: this.props.selectedCategory && !isEmpty(this.props.selectedCategory) });
        }
    }

    state = {
        string: undefined,
        isLoadingLocation: false,
        onlyInCategory: !!this.props.selectedCategory,
        locationOpen: false
    };

    buildURL(params, useCategoryCoverIfNeeded = true) {
        const { config } = this.props;

        return buildURL(params, useCategoryCoverIfNeeded ? { categoryCover: config.get('categoryCover') } : undefined);
    }

    handleLocationChange = (location, locationProps) => {
        const {
            searchTerm,
            trackOrigin,
            _setGPS,
            _setLocationGps,
            config
        } = this.props;
        const { string } = this.state;
        const search = (typeof string === 'undefined') ? searchTerm : string;

        this.props.track(LOCATION_COMPLETE, {
            origin: trackOrigin,
            select_from: OMNIBOX,
            place_selected_id: location ? location.id : '',
            ...locationProps,
            ...LayoutHelper.getAcquisitionChannelTrackingValues()
        });

        // to check if the location is manually selected and not the default location
        setManualLocation(true);

        this.setState({ locationOpen: false });

        locationByHomeAndLatLong(location, this.props, search, config, _setGPS, _setLocationGps);

        window.scrollTo(0, 0);
        return null;
    };

    handleTextChange = value => {
        this.setState({
            string: value || ''
        });
    };

    handleOnLoading = isLoadingLocation => this.setState({ isLoadingLocation });

    handleOnSelectCategoryOnly = () =>
        this.setState({ onlyInCategory: !this.state.onlyInCategory });

    handleSearch = (_, suggestions) => {
        const { selectedLocation: location, searchTerm, selectedCategory, config } = this.props;
        const { string } = this.state;
        const category = this.isMXCL || this.state.onlyInCategory ? selectedCategory : undefined;
        const search = (typeof string === 'undefined') ? searchTerm : searchSanitization(string);
        const params = config.get('autoSelectCategories') ? {
            isSearchCall: !this.state.onlyInCategory
        } : {};

        this.completeSearch(
            this.buildURL({ location, category, search, params }),
            {
                search_type: SEARCH_TYPE.REGULAR,
                search_string: search,
                search_user_query: search,
                search_autocomplete_suggestions: suggestions
            }
        );
    };

    handleSearchClick = ({ category, title, suggestions, recentSearch_selected_position, ...rest }) => {
        const { selectedLocation: location, config, selectedCategory } = this.props;
        const { string } = this.state;
        const autoSelectCategoryFlag = config.get('autoSelectCategories');
        const hasCategory = category && !!category.id;
        const params = autoSelectCategoryFlag && !hasCategory ? {
            isSearchCall: !hasCategory
        } : {};
        const sel_cat = this.isMXCL && !hasCategory ? selectedCategory : category;

        this.completeSearch(
            this.buildURL({ location, category: sel_cat, search: title, params }),
            {
                search_type: recentSearch_selected_position ? SEARCH_TYPE.SAVED_HISTORY : SEARCH_TYPE.AUTOCOMPLETE,
                search_string: title,
                search_user_query: string,
                search_autocomplete_suggestions: suggestions,
                recentSearch_selected_position,
                ...rest
            }
        );
    };

    completeSearch(url, trackingProps = {}) {
        const { trackOrigin, params: { categoryID, geoID }, location } = this.props;
        const props = {
            origin: trackOrigin,
            category_id: categoryID || -1,
            ...this.searchTrackInfo,
            ...trackingProps
        };
        const urlObject = buildObjectFromURL(locationToString(location));

        if (geoID) {
            props.location_id = geoID;
        }
        if (urlObject.params && urlObject.params.filters) {
            props.filters = urlObject.params.filters;
        }

        setSessionStorageItem('search_type', props.search_type);
        this.props.track(SEARCH_COMPLETE, props);
        this.props.router.push(url);
        window.scrollTo(0, 0);
    }

    handleLocation = status => {
        if (status !== this.state.locationOpen) {
            this.setState({ locationOpen: status });
        }
    }

    closeLocation = () => this.handleLocation(false);
    openLocation = () => this.handleLocation(true);
    onOutsideClickHandler = () => {
        return this.theme === THEMES.LETGO ? this.closeLocation() : undefined;
    }

    render() {
        const { searchTerm, isBrowsingInCategory, isBrowsingInGeoLocation, selectedCategory, isMeetingPage } = this.props;
        const { string, isLoadingLocation } = this.state;
        const search = (typeof string === 'undefined') ? searchTerm : string;
        const LocationAutocomplete = this.isMXCL ? LocationAutocompleteMX : LocationAutocompleteOld;
        const isLetgo = this.theme === THEMES.LETGO;

        return (
            <div className={ css.heroSearchWrapper }>
                <div className={ classNames(
                    css.heroSearch,
                    { [css.mxcl]: this.isMXCL }
                ) }>
                    <OutsideClickListener display="flex" onEscape={ this.closeLocation } onOutsideClick={ this.onOutsideClickHandler }>
                        <LocationAutocomplete
                            visible={ this.state.locationOpen }
                            onFocus={ this.openLocation }
                            onLostFocus={ this.closeLocation }
                            data-aut-id="heroSearchLocation"
                            onChange={ this.handleLocationChange }
                            onLoading={ this.handleOnLoading }
                            showLoading={ false }
                            trackSelectFrom={ OMNIBOX }
                            withArrow
                            locIcon={ isLetgo && 'location' || '' }
                        />
                    </OutsideClickListener>

                    {!isMeetingPage && <div className={ classNames(
                        css.searchWrapper,
                        { [css.thinBorder]: isLetgo }
                    ) }>
                        <Search
                            data-aut-id="heroSearchInputSearch"
                            className={ css.search }
                            formClassName={ css.form }
                            searchTerm={ search }
                            isBrowsingInCategory={ isBrowsingInCategory }
                            isBrowsingInGeoLocation={ isBrowsingInGeoLocation }
                            onEnter={ this.handleSearch }
                            setSearchTrackInfo={ this.setSearchTrackInfo }
                            onChange={ this.handleTextChange }
                            onClick={ this.handleSearchClick }
                        />
                        { !this.isMXCL && this.props.selectedCategory && (
                            <div className={ css.searchOnlyIn }>
                                <div className={ css.container }>
                                    <Checkbox
                                        data-aut-id="heroSearchOnlyInCategory"
                                        name="onlyInCategory"
                                        value={ !!this.props.selectedCategory }
                                        onChange={ this.handleOnSelectCategoryOnly }
                                        id="heroSearchOnlyInCategory"
                                    />
                                    <Translation id="searchOnlyIn" values={ selectedCategory }>
                                        { text => <span className={ css.label }>{text}</span> }
                                    </Translation>
                                </div>
                            </div>
                        )}
                    </div>}
                    {!isMeetingPage && <div
                        data-aut-id="btnSearch"
                        onClick={ this.handleSearch }
                        className={ classNames(css.searchButton, { [css.searchButtonLoading]: isLoadingLocation }) }
                    >
                        <span>
                            <Icon
                                icon="search"
                                size={ this.isMXCL ? 16 : 24 } // eslint-disable-line no-magic-numbers
                                color="whiteIcons"
                            />
                        </span>
                    </div>}
                </div>
            </div>
        );
    }
}

export const mapStateToProps = (state, { params }) => {
    const categoryId = categoryIdSelector(state, params);

    return {
        trackOrigin: state.track.origin,
        selectedLocation: selectedLocationSelector(state),
        selectedCategory: categorySelector(state, categoryId),
        isCurrentGPSLocation: isCurrentGPSLocationSelector(state),
        tabName: state.cxeLayout.tabName
    };
};

const mapDispatchToProps = ({
    updateLocation,
    resetLocation,
    _setGPS: setGPS,
    _setLocationGps: setLocationGps
});

export default compose(
    withTrack,
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withConfig
)(HeroSearch);
