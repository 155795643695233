import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'panamera-react-ui';

import css from '../Footer.scss';
import withConfig from 'HOCs/withConfig/withConfig';
import FooterBrand from './FooterBrand.APP_TARGET';

export class Bottom extends React.Component {
    static propTypes = {
        country: PropTypes.object.isRequired,
        brand: PropTypes.string.isRequired,
        sections: PropTypes.array,
        intl: PropTypes.shape({
            formatMessage: PropTypes.func.isRequired
        }).isRequired,
        zendeskUrl: PropTypes.string,
        footerTitle: PropTypes.string,
        footerAddress: PropTypes.string,
        config: PropTypes.shape({
            get: PropTypes.func.isRequired
        }),
        isOlxAutosLandingPage: PropTypes.bool
    };

    static defaultProps = {
        sections: [],
        footerTitle: '',
        footerAddress: ''
    };

    constructor(props) {
        super(props);

        this.translations = {
            footer: values => <span>{ this.props.intl.formatMessage({ id: 'footer' }, values) }</span>
        };
        const { config, isOlxAutosLandingPage } = this.props;
        const olxAutos = config.get('olxAutos');

        this.isMxCl = isOlxAutosLandingPage || false; // !!(olxAutos && olxAutos.isMXCL) || false;
        this.olxAutosFooter = !!(olxAutos && olxAutos.isMXCL) || false;
        this.disableSiteMapLink = olxAutos.disableSiteMapLink;
        this.disableHelpLink = olxAutos.disableHelpLink;
    }

    getFooterText() {
        const { country, brand, footerTitle, footerAddress } = this.props;
        let bottomFooterText = '';
        let copyRightDate = '';

        if (this.olxAutosFooter) {
            copyRightDate = `${(new Date()).getFullYear()} ©`;
            bottomFooterText = (<>{copyRightDate} {footerTitle}</>);
        }
        else {
            copyRightDate = `© 2006-${(new Date()).getFullYear()}`;
            const title = footerTitle || this.translations.footer({ country: country.name });
            const address = footerAddress || brand;

            bottomFooterText = (<>{title}{footerTitle ? '' : '.'} {copyRightDate} {address}</>);
        }
        return bottomFooterText;
    }

    getFallbackSection = () => {
        const { country, intl, zendeskUrl } = this.props;
        const section = {
            id: 'links-section',
            title: '',
            links: []
        };

        if (zendeskUrl && !this.disableHelpLink) {
            section.links.push({
                title: intl.formatMessage({ id: 'help' }),
                href: zendeskUrl
            });
        }

        if (!this.disableSiteMapLink) {
            section.links.push({
                title: intl.formatMessage({ id: 'sitemap' }),
                href: '/sitemap/most-popular'
            });
        }

        if (country.privacy_links) {
            section.links.push({
                title: intl.formatMessage({ id: 'terms_of_use' }),
                href: country.privacy_links.terms_url
            });

            section.links.push({
                title: intl.formatMessage({ id: 'privacy_policy' }),
                href: country.privacy_links.privacy_url
            });
        }

        return section;
    };

    getLinksSection = (sections, separator = ' - ') => {
        let section = sections.find(_section => _section.id === 'other-countries');

        if (!section) {
            section = this.getFallbackSection();
        }

        const links = [];

        section.links
            .forEach((link, index) => links.push(
                separator,
                <Link
                    key={ index }
                    className={ css.links }
                    to={ link.href }
                >
                    { link.title }
                </Link>
            ));

        // It is used to remove first separator
        links.shift();

        return (
            <section className={ `${css.section} ${css.autosSection}` }>
                <div className={ `${css.autosSectionTitle}` }>{ section.title }</div> { links }
            </section>
        );
    };

    render() {
        const { sections } = this.props;

        return (
            <div className={ css.bottomFooter } data-aut-id="bottom-footer">
                <FooterBrand />
                <div className={ css.sections }>
                    <section className={ css.section }>
                        { this.getFooterText() }
                    </section>
                    { this.olxAutosFooter ? this.getLinksSection(sections, ' • ') : this.getLinksSection(sections)}
                </div>
            </div>
        );
    }
}

export default withConfig(Bottom);
