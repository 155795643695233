/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from 'react';
import { Link } from 'panamera-react-ui';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import css from './FavouriteCTA.scss';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';
import { favouritesIdsCountSelector } from 'Selectors/user';
import { getUserFavouritesIds } from 'Actions/user';
import classnames from 'classnames';
import { FAVOURITES, ICONS } from 'Constants/items';
import { MY_ZONE_ROUTE } from 'Constants/comparePage';
import withTrack from 'HOCs/withTrack/withTrack';
import { MYZONE_EVENTS } from 'Constants/tracking';

const maxLimit = 10;
const route = `${MY_ZONE_ROUTE}?filter=${FAVOURITES}`;

const FavouriteCTA = ({
    direction = 'rtl',
    userFavCount = 0,
    getUserFavourites,
    classname = '',
    key = 'favlink',
    dataAutId,
    isLoggedIn = false,
    track,
    flowStep,
    trackerOrigins
}) => {
    useEffect(() => {
        if (isLoggedIn) {
            getUserFavourites();
        }
    }, [isLoggedIn]);

    const displayCountValue = count => {
        const displayText = count < maxLimit ? count : '9+';

        return (<span> { displayText } </span>);
    };

    const trackFavCTAClick = () => {
        track(MYZONE_EVENTS.MY_ZONE_OPEN, {
            select_from: flowStep,
            result_count: userFavCount,
            chosen_option: trackerOrigins.SHORTLIST
        });
    };

    return (<Link
        key={ key }
        to={ route }
        className={ classnames(css.link, classname) }
        data-aut-id={ dataAutId }
        onClick={ trackFavCTAClick }
    >
        <NewIconButton
            name={ 'favButton' }
            icon={ ICONS.FAV_OFF }
            direction={ direction }
            data-aut-id={ 'favButton' }
        />
        { !!userFavCount && <div className={ css.bubble }> {displayCountValue(userFavCount)} </div>}
    </Link>);
};

const mapStateToProps = state => ({
    userFavCount: favouritesIdsCountSelector(state)
});

const mapDispatchToProps = dispatch => ({
    getUserFavourites: () => dispatch(getUserFavouritesIds())
});

FavouriteCTA.propTypes = {
    direction: PropTypes.string,
    userFavCount: PropTypes.number,
    getUserFavourites: PropTypes.func,
    classname: PropTypes.string,
    key: PropTypes.string,
    dataAutId: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    flowStep: PropTypes.string,
    track: PropTypes.func,
    trackerOrigins: PropTypes.object.isRequired
};

export default compose(
    withTrack,
    connect(mapStateToProps, mapDispatchToProps)
)(FavouriteCTA);
